@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Figtree:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@font-face {
  font-family: Gotham;
  src: url('./assets/fonts/GothamMedium.ttf');
}

:root {
  --primaryLighter: #fcaca1;
  --primaryLight: #fb8673;
  --primaryMain: #fb5012;
  --primaryDark: #c23d0d;
  --primaryDarker: #702308;

  --secondaryLighter: #c6c6c7;
  --secondaryLight: #737476;
  --secondaryMain: #101a20;
  --secondaryDark: #0e171d;
  --secondaryDarker: #0a1014;

  --text-5xl: 3rem;
  --text-4xl: 2.25rem;
  --text-3xl: 1.875rem;
  --text-2xl: 1.5rem;
  --text-xl: 1.25rem;
  --text-lg: 1.125rem;
  --text-base: 1rem;
  --text-sm: 0.875rem;
  --text-xs: 0.75rem;
}

body {
  background-color: #fff;
  overflow-x: auto;
  min-height: 100vh;
}

* {
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;
  font-family: inherit;
}

/* @Calander Styles Override */

.Calendar {
  padding-bottom: 30px !important;
  z-index: 1 !important;
}

.Calendar__weekDay {
  font-family: 'Lato', sans-serif !important;
  color: '#fff';
}

.Calendar__monthText {
  font-family: 'Lato', sans-serif !important;
  color: #ffff !important;
  pointer-events: none;
}

.Calendar__monthText:hover {
  color: #101a20 !important;
}

.Calendar__yearText {
  font-family: 'Lato', sans-serif !important;
  color: #ffff !important;
}

.Calendar__weekRow {
  margin: 5px 0 !important;
  gap: 5px;
}

.Calendar__day {
  color: #ffff !important;
  border-radius: 5px !important;
  width: calc((100% - 30px) / 7) !important;
  border: 1px solid #727475 !important;
  background-color: #a1a2a3 !important;
  cursor: not-allowed !important;
  pointer-events: none;

}

.Calendar__day:hover {
  /* color: #29d697 !important; */
}

.Calendar__day.-selected {
  background: var(--primaryMain) !important;
  color: #fff !important;
}

div.Calendar__day.-disabled {
  color: #fff !important;
  border: 1px solid #727475 !important;
  cursor: default !important;
  background-color: #a1a2a3 !important;
  cursor: not-allowed !important;

}

.Calendar__monthArrow {
  filter: brightness(0) invert(1) !important;
}

.Calendar__section.-hiddenNext {
  opacity: 0 !important;
  transform: translateX(90%) !important;
}

.Calendar__section.-hiddenPrevious {
  opacity: 0 !important;
  transform: translateX(-90%) !important;
}

.Calendar__day.-ltr.-blank {
  background-color: #101a20 !important;
  border: 1px solid #101a20 !important;
}

.default-calander {
  width: 375px !important;
  background-color: #101a20 !important;
  font-family: 'Lato', sans-serif !important;
}

.analysis-custom-calendar {
  width: 100% !important;
  background-color: #101a20 !important;
  font-family: 'Lato', sans-serif !important;
}

.create-booking-custom-calendar {
  width: 400px !important;
  background-color: #101a20 !important;
  font-family: 'Lato', sans-serif !important;
}

.bookingFullClass {
  color: var(--primaryMain) !important;
  background-color: #fff !important;
  border-radius: 5px !important;
  pointer-events: none;
}

.bookingLessThan50 {
  color: #54d62c !important;
  background-color: #fff !important;
  border-radius: 5px !important;
  pointer-events: auto !important;
  cursor: pointer !important;
}

.booking50To100 {
  color: #ffc107 !important;
  background-color: #fff !important;
  border-radius: 5px !important;
  pointer-events: auto !important;
  cursor: pointer !important;
}

.bookingAbove100 {
  color: #ff4842 !important;
  background-color: #fff !important;
  border-radius: 5px !important;
  pointer-events: auto !important;
  cursor: pointer !important;
}

.bookingAbove100AndDisabled {
  color: #ffff !important;
  border: 1px solid #727475 !important;
  background-color: #a1a2a3 !important;
  pointer-events: none;
  cursor: pointer !important;
}

.disabledWeekedClass {
  color: #727475 !important;
  border: 1px solid #727475 !important;
  background-color: #101a20 !important;
  cursor: default !important;
  pointer-events: none;
}

/* @Toast Notifications Styles Override */

.react-toast-notifications__toast__content .css-18gu508-Content {
  font-family: 'Lato', sans-serif !important;
}

.react-toast-notifications__container div {
  font-family: 'Lato', sans-serif !important;
}

.css-nvf14r-ToastContainer {
  z-index: 20000 !important;
}

/* @DropDown Styles  Override */

.css-wmy1p7-ReactDropdownSelect:hover {
  border: 1px solid var(--primaryLighter) !important;
}

.css-z2hyoe-ItemComponent.react-dropdown-select-item-selected {
  background-color: var(--primaryMain) !important;
}

.css-z2hyoe-ItemComponent:hover {
  background-color: #ffeee7 !important;
}

/* MUI styles over-ride */

/* Phone input styles over-ride */
.react-tel-input .country-list {
  display: flex !important;
  flex-direction: column !important;
  align-items: flex-start !important;
  padding: 0 20px;
}

.phoneInput2Container {
  height: 50px !important;
  font-family: 'Lato', sans-serif !important;
}

.phoneInput2Input {
  height: 50px !important;
  font-family: 'Lato', sans-serif !important;
  width: 100% !important;
  background-color: #fff !important;
  border: 2px solid #000 !important;
}

.phoneInput2Input:focus {
  box-shadow: none !important;
  border: 2px solid #fb5012 !important;
}

.react-tel-input .country-list li {
  display: block !important;
  font-family: 'Lato', sans-serif !important;
}

.react-tel-input .flag-dropdown.open {
  box-shadow: none !important;
}

::-webkit-scrollbar {
  display: none !important;
}
::-webkit-scrollbar-track {
  display: none !important;
}
::-webkit-scrollbar-thumb {
  display: none !important;
}

.rts___tabs {
  padding: 0;
}
.rts___tab {
  margin: 0;
  position: relative;
}
.rts___nav___btn svg {
  max-width: unset;
}

.rts___btn {
  border-radius: unset !important;
  border: none !important;
  font-family: 'Lato', sans-serif !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  text-transform: uppercase !important;
}

.rts___tab::after {
  content: '';
  margin: auto;
  height: 3px;
  background: transparent;
  transition: width 0.5s ease, background-color 0.5s ease;
  width: 0;
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
}

.rts___tab___selected {
  color: #000 !important;
  position: relative !important;
  width: 100% !important;
  background: transparent !important;
  box-shadow: none !important;
}

.rts___tab___selected::after {
  background: #fb5012;
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 3px;
}

.rts___nav___btn:hover {
  background-color: unset;
}

.rts___nav___btn:hover > svg {
  stroke: rgba(0, 0, 0, 0.6);
}

@media (max-width: 991.98px) {
  .rts___tabs___container {
    padding: 5;
  }
}
@media (max-width: 767.98px) {
  .rts___tab {
    padding: 10px;
  }
}

.bookingFullClass {
  color: var(--primaryMain) !important;
  background-color: #fff !important;
  border-radius: 5px !important;
  pointer-events: none;
}

.bookingLessThan50 {
  color: #54d62c !important;
  background-color: #fff !important;
  border-radius: 5px !important;
  pointer-events: auto !important;
}

.booking50To100 {
  color: #ffc107 !important;
  background-color: #fff !important;
  border-radius: 5px !important;
  pointer-events: auto !important;
}

.bookingAbove100 {
  color: #ff4842 !important;
  background-color: #fff !important;
  border-radius: 5px !important;
  pointer-events: auto !important;
}

.bookingAbove100AndDisabled {
  color: #ffff !important;
  border: 1px solid #727475 !important;
  background-color: #a1a2a3 !important;
  pointer-events: none;
}

.disabledWeekedClass {
  color: #727475 !important;
  border: 1px solid #727475 !important;
  background-color: #101a20 !important;
  cursor: default !important;
  pointer-events: none;
}

.Calendar__sectionWrapper {
  min-height: 30.8em !important;
}

.number-input::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
