.container {
  background-color: #1c2127;
  height: 100vh;
  overflow: hidden;
  display: grid;
  place-items: center;
}

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 25px;
  align-items: center;
}

.message1 {
  font-family: 'Poppins', sans-serif;
  font-size: 30px;
  color: white;
  font-weight: 500;
  text-align: center;
}

.message2 {
  font-family: 'Poppins', sans-serif;
  font-size: 18px;
  color: white;
  font-weight: 300;
  text-align: center;
}

.neon {
  text-align: center;
  font-family: 'Varela Round', sans-serif;
  font-size: 90px;
  color: #5be0b3;
  letter-spacing: 3px;
  text-shadow: 0 0 5px #6eecc1;
  animation: flux 2s linear infinite;
}

@keyframes flux {
  0%,
  100% {
    text-shadow: 0 0 5px #00ffc6, 0 0 15px #00ffc6, 0 0 50px #00ffc6, 0 0 50px #00ffc6, 0 0 2px #b9ffe8,
      2px 2px 3px #12e29c;
    color: #4bffef;
  }
  50% {
    text-shadow: 0 0 3px #00b58d, 0 0 7px #00b58d, 0 0 25px #00b58d, 0 0 25px #00b58d, 0 0 2px #00b58d,
      2px 2px 3px #006a60;
    color: #63d3ae;
  }
}
